import { Capacitor } from "@capacitor/core";
import { SecureStoragePlugin } from "capacitor-secure-storage-plugin";

export type LoginCredentials = {
  email: string;
  password: string;
  remember?: boolean;
  mobile_app?: boolean;
};

export type RegisterCredentials = {
  email: string;
  password: string;
  password_confirmation: string;
  privacy_accepted: boolean;
  disclaimer_accepted: boolean;
};

export type ResetPasswordCredentials = {
  email: string;
  password: string;
  password_confirmation: string;
  token: string;
};

export const useAuth = () => {
  const router = useRouter();

  const user = useUser();

  const isLoggedIn = computed(() => !!user.value);

  async function refresh() {
    try {
      user.value = await fetchCurrentUser();
    } catch {
      user.value = null;
    }
  }

  async function login(credentials: LoginCredentials) {
    if (Capacitor.isNativePlatform()) {
      await loginMobile(credentials);
    } else {
      await loginWeb(credentials);
    }
  }

  async function loginWeb(credentials: LoginCredentials) {
    if (isLoggedIn.value) return;

    await $larafetch("/login", { method: "post", body: credentials });
    await refresh();
  }

  async function loginMobile(credentials: LoginCredentials) {
    if (isLoggedIn.value) return;

    credentials.mobile_app = true;

    const response: any = await $larafetch("/mobile/login", {
      method: "post",
      body: credentials,
    });

    await SecureStoragePlugin.set({
      key: "authToken",
      value: response.token,
    });

    await refresh();
  }

  async function register(credentials: RegisterCredentials) {
    if (Capacitor.isNativePlatform()) {
      await registerMobile(credentials);
    } else {
      await registerWeb(credentials);
    }
  }

  async function registerWeb(credentials: RegisterCredentials) {
    await $larafetch("/register", { method: "post", body: credentials });
    await refresh();
  }

  async function registerMobile(credentials: RegisterCredentials) {
    const response = await $larafetch("/mobile/register", {
      method: "post",
      body: credentials,
    });

    await SecureStoragePlugin.set({
      key: "authToken",
      value: response.token,
    });

    await refresh();
  }

  async function resendEmailVerification() {
    return await $larafetch<{ status: string }>(
      "/email/verification-notification",
      {
        method: "post",
      },
    );
  }

  async function verifyEmail(
    id: number,
    hash: string,
    expires: string,
    signature: string,
  ) {
    return await $larafetch<{ status: string }>(
      `/verify-email/${id}/${hash}?expires=${expires}&signature=${signature}`,
      {
        method: "post",
      },
    );
  }

  async function logout() {
    if (!isLoggedIn.value) return;

    const url = Capacitor.isNativePlatform() ? "/mobile/logout" : "/logout";

    await $larafetch(url, { method: "post" });
    user.value = null;

    if (Capacitor.isNativePlatform()) {
      await SecureStoragePlugin.remove({ key: "authToken" });
    }

    // Clear history and context from local storage
    const { clearMessages } = useChat();
    clearMessages();

    const contextStore = useContextStore();
    contextStore.clearContext();

    await router.push("/welcome");
  }

  async function forgotPassword(email: string) {
    return await $larafetch<{ status: string }>("/forgot-password", {
      method: "post",
      body: { email },
    });
  }

  async function resetPassword(credentials: ResetPasswordCredentials) {
    return await $larafetch<{ status: string }>("/reset-password", {
      method: "post",
      body: credentials,
    });
  }

  return {
    user,
    isLoggedIn,
    login,
    register,
    resendEmailVerification,
    verifyEmail,
    logout,
    forgotPassword,
    resetPassword,
    refresh,
  };
};
